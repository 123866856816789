import React, { createContext, useReducer, useContext } from 'react'
import { State, Action, Dispatch, StateProviderProps } from './types'

const StateContext = createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined)

const stateReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'updateProductTab': {
      return { ...state, activeProductTab: action.index }
    }
    case 'updateBenefitsTab': {
      return { ...state, activeBenefitsTab: action.index }
    }
    case 'updateFormInterest': {
      return { ...state, interestForm: action.value }
    }
    case 'loadApp': {
      return { ...state, homeHasLoaded: true }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const StateProvider = ({ children }: StateProviderProps) => {
  const [state, dispatch] = useReducer(stateReducer, {
    activeProductTab: 0,
    activeBenefitsTab: 0,
    interestForm: '',
    homeHasLoaded: false,
  })
  const value = { state, dispatch }

  return <StateContext.Provider value={value}>{children}</StateContext.Provider>
}

const useSt = () => {
  const context = useContext(StateContext)
  if (context === undefined) {
    throw new Error('useSt must be used within a GlobalProvider')
  }
  return context
}

export { StateProvider, useSt }
