/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react'
import { I18nextProvider } from 'react-i18next'
import { StateProvider } from 'state/global/context'
import i18n from './src/i18n/config'
import '@fontsource/inter'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'

export const wrapRootElement = ({ element }) => {
  return (
    <StateProvider>
      <I18nextProvider i18n={i18n}>{element}</I18nextProvider>
    </StateProvider>
  )
}
