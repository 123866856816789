exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-early-adopters-tsx": () => import("./../../../src/pages/early-adopters.tsx" /* webpackChunkName: "component---src-pages-early-adopters-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-investor-tsx": () => import("./../../../src/pages/investor.tsx" /* webpackChunkName: "component---src-pages-investor-tsx" */),
  "component---src-pages-legal-mentions-tsx": () => import("./../../../src/pages/legalMentions.tsx" /* webpackChunkName: "component---src-pages-legal-mentions-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacyPolicy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-technology-tsx": () => import("./../../../src/pages/technology.tsx" /* webpackChunkName: "component---src-pages-technology-tsx" */)
}

