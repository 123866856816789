import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import TRANSLATIONS_EN from './en/translations.json'
import TRANSLATIONS_FR from './fr/translations.json'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      fr: {
        translation: TRANSLATIONS_FR,
      },
      en: {
        translation: TRANSLATIONS_EN,
      },
    },
    fallbackLng: 'en',
  })

// i18n.changeLanguage('en')

export default i18n
